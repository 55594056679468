<template>
  <div id="kunjungan-ibu-hamil">
    <b-container fluid>
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
          <b-card>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <b-form-group label-cols-md="4">
                  <template v-slot:label> Bulan </template>
                  <b-form-select
                    v-model="data_search.bulan"
                    :options="optionBulan"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <b-form-group label-cols-md="4">
                  <template v-slot:label> Tahun </template>
                  <b-form-select
                    v-model="data_search.tahun"
                    :options="optionTahun"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <div style="width: 100%; display: flex">
                  <div
                    style="
                      width: 50%;
                      height: 30px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                    "
                  >
                    <h6 style="margin: 0; font-weight: bold">Puskesmas</h6>
                  </div>
                  <div
                    style="
                      width: 50%;
                      height: 30px;
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                    "
                  >
                    <b-button variant="outline-primary" size="sm">
                      <b-form-checkbox
                        id="check_puskesmas"
                        name="check_puskesmas"
                        v-model="checked"
                        @change="toggleSelect()"
                      >
                        <span class="pt-1">Pilih Semua</span>
                      </b-form-checkbox>
                    </b-button>
                  </div>
                </div>
                <hr />
                <b-row style="padding-left: 7.5px; margin-top: 15px">
                  <b-col
                    v-for="(item, idx) in options_puskesmas"
                    :key="idx"
                    cols="12"
                    sm="12"
                    md="6"
                    lg="4"
                    xl="4"
                  >
                    <b-form-checkbox v-model="item.checked" @change="addSelect()">
                      {{ item.nama_puskesmas }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>

              <!-- <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <hr />
                <div style="width: 100%; display: flex">
                  <div
                    style="
                      width: 50%;
                      height: 30px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                    "
                  >
                    <h6 style="margin: 0; font-weight: bold">Poli / Ruangan</h6>
                  </div>
                  <div
                    style="
                      width: 50%;
                      height: 30px;
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                    "
                  >
                    <b-button variant="outline-primary" size="sm">
                      <b-form-checkbox
                        id="check_poli"
                        name="check_poli"
                        v-model="checked_poli"
                        @change="toggleSelectPoli()"
                      >
                        <span class="pt-1">Pilih Semua</span>
                      </b-form-checkbox>
                    </b-button>
                  </div>
                </div>
                <hr />
                <b-row style="padding-left: 7.5px; margin-top: 15px">
                  <b-col
                    v-for="(item, idx) in options_poli"
                    :key="idx"
                    cols="12"
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                  >
                    <b-form-checkbox v-model="item.checked" @change="addSelectPoli()">
                      {{ item.nama_poli }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col> -->
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr />
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div style="display: flex; justify-content: flex-start">
                  <b-button @click="getDataKunjungan()" variant="primary" class="mr-2"
                    >Tampilkan</b-button
                  >
                  <!-- <b-button variant="success" class="mr-2">Export to Excel</b-button> -->
                  <b-button variant="warning" @click="resetDataKunjungan">Reset</b-button>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr />
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-center" cols="12" sm="12" md="12" lg="12" xl="12">
                <h5>
                  <strong>LAPORAN BULANAN - KUNJUNGAN IBU HAMIL</strong>
                </h5>
                <p>{{ data_search.bulan ? data_search.bulan.toUpperCase() : 'NOVEMBER' }} - {{ data_search.tahun ? data_search.tahun : "2023" }}</p>
              </b-col>
            </b-row>

            
            <!-- <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <h5 >
                  <strong>Tanggal: {{ data_search.tanggal_mulai }} - {{ data_search.tanggal_selesai }}</strong>
                </h5>
              </b-col>
            </b-row> -->

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <!-- <div class="text-end">Periode : {{ $moment(data_search.tanggal_mulai).format("LL") }} - {{ $moment(data_search.tanggal_selesai).format("LL") }}</div> -->
                <b-table-simple bordered responsive hover>
                  <b-thead head-variant="dark" class="text-center">
                    <b-tr>
                      <b-th rowspan="4">No.</b-th>
                      <b-th colspan="13">Register</b-th>
                      <b-th colspan="16">Pemeriksaan</b-th>
                      <b-th rowspan="4">Konseling</b-th>
                      <b-th rowspan="4">Status Imunisasi TT</b-th>
                      <b-th colspan="4">Pelayanan</b-th>
                      <b-th colspan="8">Laboratorium</b-th>
                      <b-th colspan="47">Integrasi Program</b-th>
                      <b-th rowspan="4">Keterangan</b-th>
                    </b-tr>

                    <b-tr>
                      <b-th rowspan="3">Tanggal</b-th>
                      <b-th rowspan="3">NIK</b-th>
                      <!-- <b-th rowspan="3">No. Ibu</b-th> -->
                      <b-th rowspan="3">Nama Ibu</b-th>
                      <b-th rowspan="3">Umur</b-th>
                      <b-th rowspan="3">Tanggal Lahir</b-th>
                      <b-th rowspan="3">No. Telp</b-th>
                      <b-th rowspan="3">Alamat</b-th>
                      <b-th rowspan="3">BPJS</b-th>
                      <b-th rowspan="3">Tanggal HPHT</b-th>
                      <b-th rowspan="3">Taksiran Persalinan</b-th>
                      <b-th rowspan="3">Usia Kehamilan</b-th>
                      <b-th rowspan="3">Trisemester</b-th>
                      <b-th rowspan="3">Faskes Asal</b-th>

                      <b-th colspan="8">Ibu</b-th>
                      <b-th colspan="8">Bayi</b-th>
                      <b-th rowspan="3">Jumlah Janin</b-th>

                      <b-th rowspan="3">Injeksi TT</b-th>
                      <b-th rowspan="3">Catat dibuku KIA</b-th>
                      <b-th rowspan="3">Fe (Tab / Botol)</b-th>

                      <b-th colspan="2">Periksa HB</b-th>

                      <b-th rowspan="3">Protein Uria (+/-)</b-th>
                      <b-th rowspan="3">Gula Darah</b-th>
                      <b-th rowspan="3">Thalasemia (+/-)</b-th>
                      <b-th rowspan="3">Sifilis (+/-)</b-th>
                      <b-th rowspan="3">HBsAg (+/-)</b-th>
                      <b-th rowspan="3">Anemia (+/-)</b-th>

                      <b-th colspan="7">Pencegahan Penularan HIV dari Ibu ke Anak (PPIA)</b-th>
                      <b-th colspan="7">Pencegahan Malaria Dalam Kehamilan (PMDK)</b-th>

                      <b-th colspan="3">TB dalam Kehamilan</b-th>
                      <b-th colspan="2">Infeksi Cacing dalam Kehamilan</b-th>
                      <b-th colspan="3">Pencegahan IMS dalam Kehamilan</b-th>
                      <b-th colspan="2">Pencegahan Hepatitis dalam Kehamilan</b-th>
                      <b-th colspan="9">Risiko Terdeteksi Oleh**</b-th>
                      <b-th colspan="6">Komplikasi**</b-th>
                      <b-th colspan="6">Kegiatan Rujukan**</b-th>

                      <b-th rowspan="3">Keadaan Tiba (H/M)</b-th>
                      <b-th rowspan="3">Keadaan Pulang (H/M)</b-th>
                    </b-tr>
                    <b-tr>
                      <b-th rowspan="2">Anamnesis</b-th>
                      <b-th rowspan="2">BB (kg)</b-th>
                      <b-th rowspan="2">TB (cm)</b-th>
                      <b-th rowspan="2">TD (mmHg)</b-th>
                      <b-th rowspan="2">LILA (cm)</b-th>
                      <b-th rowspan="2">Status Gizi</b-th>
                      <b-th rowspan="2">TFU (cm)</b-th>
                      <b-th rowspan="2">Refleks Patella (+/-)</b-th>

                      <b-th colspan="4">Tunggal</b-th>
                      <b-th colspan="4">Jamak</b-th>

                      <b-th rowspan="2">Dilakukan</b-th>
                      <b-th rowspan="2">Hasil (gr/dl)</b-th>

                      <b-th rowspan="2">Datang dengan HIV (+/-)</b-th>
                      <b-th rowspan="2">Ditawarkan tes</b-th>
                      <b-th rowspan="2">Dilakukan tes</b-th>
                      <b-th rowspan="2">Hasil Tes HIV (+/-)</b-th>
                      <b-th rowspan="2">Mendapatkan ART</b-th>

                      <b-th colspan="2">Ibu Hamil HIV</b-th>
                      <b-th rowspan="2">Diberikan Kelambu Berinsteksida</b-th>
                      <b-th colspan="2">Darah Malaria Diperiksa Ibu Hamil</b-th>
                      <b-th colspan="2">Ibu Hamil Malaria</b-th>
                      <b-th rowspan="2">Hasil Tes Darah Malaria(+/-)</b-th>
                      <b-th rowspan="2">Obat Kina/ACT***</b-th>
                      <b-th rowspan="2">Diperiksa dahak*</b-th>
                      <b-th rowspan="2">TBC (+/-)</b-th>
                      <b-th rowspan="2">Obat***</b-th>
                      <b-th rowspan="2">Diperiksa Ankylostoma</b-th>
                      <b-th rowspan="2">Ibu Hamil Tes (+/-)</b-th>
                      <b-th rowspan="2">Diperiksa IMS</b-th>
                      <b-th rowspan="2">Hasil Tes (+/-)</b-th>
                      <b-th rowspan="2">Diterapi</b-th>
                      <b-th rowspan="2">Diperiksa Hepatitis</b-th>
                      <b-th rowspan="2">Hasil Tes (+/-)</b-th>
                      <b-th rowspan="2">Pasien</b-th>
                      <b-th rowspan="2">Keluarga</b-th>
                      <b-th rowspan="2">Masyarakat</b-th>
                      <b-th rowspan="2">Dukun</b-th>
                      <b-th rowspan="2">Kader</b-th>
                      <b-th rowspan="2">Bidan</b-th>
                      <b-th rowspan="2">Perawat</b-th>
                      <b-th rowspan="2">Dokter</b-th>
                      <b-th rowspan="2">DSOG</b-th>
                      <b-th rowspan="2">HDK</b-th>
                      <b-th rowspan="2">Abortus</b-th>
                      <b-th rowspan="2">Pendarahan</b-th>
                      <b-th rowspan="2">Infeksi</b-th>
                      <b-th rowspan="2">KPD</b-th>
                      <b-th rowspan="2">Lainnya</b-th>
                      <b-th rowspan="2">Puskesmas</b-th>
                      <b-th rowspan="2">Klinik</b-th>
                      <b-th rowspan="2">RB</b-th>
                      <b-th rowspan="2">RSIA / RSB</b-th>
                      <b-th rowspan="2">RS</b-th>
                      <b-th rowspan="2">Lainnya</b-th>
                    </b-tr>
                    <b-tr>
                      <b-th>DJJ (x/menit)</b-th>
                      <b-th>Kepala thd PAP</b-th>
                      <b-th>TBJ (gr)</b-th>
                      <b-th>Presentasi</b-th>
                      
                      <b-th>DJJ (x/menit)</b-th>
                      <b-th>Kepala thd PAP</b-th>
                      <b-th>TBJ (gr)</b-th>
                      <b-th>Presentasi</b-th>

                      <b-th>Persalinan Pervaginaan</b-th>
                      <b-th>Persalinan Pravdoinan</b-th>

                      <b-th>Diperiksa RDT</b-th>
                      <b-th>Mikroskopis</b-th>
                      <b-th>Diperiksa RDT</b-th>
                      <b-th>Mikroskopis</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody v-if="items.length">
                    <b-tr v-for="(item, index) in items" :key="item.kia_id">
                      <b-td class="text-center">{{ index + 1 }}.</b-td>
                      <b-td>{{ item.tanggal_kia ? $moment(item.tanggal_kia).format("LL") : "-" }}</b-td>
                      <b-td>{{ item.nik || "-" }}</b-td>
                      <b-td>{{ item.nama_lengkap || "-" }}</b-td>
                      <b-td>
                        <span v-if="item.usia_tahun"> {{ item.usia_tahun }} Tahun </span>
                        <span v-if="item.usia_bulan"> {{ item.usia_bulan }} Bulan </span>
                        <span v-if="item.usia_hari"> {{ item.usia_hari }} Hari </span>
                      </b-td>
                      <b-td>{{ item.lahir ? $moment(item.lahir).format("LL") : "-" }}</b-td>
                      <b-td>{{ item.no_telp || "-" }}</b-td>
                      <b-td>{{ item.alamat_sekarang || "-" }}</b-td>
                      <b-td>{{ item.no_bpjs || "-" }}</b-td>
                      <b-td>{{ item.tanggal_hpht || "-" }}</b-td>
                      <b-td>{{ item.taksiran_persalinan || "-" }}</b-td>
                      <b-td class="text-center">{{ item.usia_kehamilan || "-" }}</b-td>
                      <b-td class="text-center">{{ item.trisemester || "-" }}</b-td>
                      <b-td>{{ item.faskes_asal || "-" }}</b-td>
                      <!-- IBU -->
                      <b-td>{{ item.anamnesis || "-" }}</b-td>
                      <b-td>{{ item.berat_badan || "-" }}</b-td>
                      <b-td>{{ item.tinggi_badan || "-" }}</b-td>
                      <b-td>{{ item.tekanan_darah || "-" }}</b-td>
                      <b-td>{{ item.lila || "-" }}</b-td>
                      <b-td>{{ item.status_gizi || "-" }}</b-td>
                      <b-td>{{ item.tinggi_fundus || "-" }}</b-td>
                      <b-td>{{ item.refleks_patela || "-" }}</b-td>
                      <!-- BAYI -->
                      <b-td>{{ item.djj_tunggal || "-" }}</b-td>
                      <b-td>{{ item.kepala_pap_tunggal || "-" }}</b-td>
                      <b-td>{{ item.tbj_tunggal || "-" }}</b-td>
                      <b-td>{{ item.presentasi_tunggal || "-" }}</b-td>
                      <b-td>{{ item.djj_jamak || "-" }}</b-td>
                      <b-td>{{ item.kepala_pap_jamak || "-" }}</b-td>
                      <b-td>{{ item.tbj_jamak || "-" }}</b-td>
                      <b-td>{{ item.presentasi_jamak || "-" }}</b-td>

                      <b-td>{{ item.konseling || "-" }}</b-td>
                      <b-td>{{ item.status_imunisasi || "-" }}</b-td>

                      <b-td>{{ item.jumlah_janin || "-" }}</b-td>
                      <b-td>{{ item.injeksi || "-" }}</b-td>
                      <b-td>{{ item.catat_dibuku || "-" }}</b-td>
                      <b-td>{{ item.fe || "-" }}</b-td>

                      <b-td>{{ item.hb_dilakukan || "-" }}</b-td>
                      <b-td>{{ item.hb_hasil || "-" }}</b-td>

                      <b-td>{{ item.proteinuria || "-" }}</b-td>
                      <b-td>{{ item.gula_darah || "-" }}</b-td>
                      <b-td>{{ item.thalasemia || "-" }}</b-td>
                      <b-td>{{ item.sifilis || "-" }}</b-td>
                      <b-td>{{ item.hbsag || "-" }}</b-td>
                      <b-td>{{ item.anemia || "-" }}</b-td>

                      <b-td>{{ item.hiv || "-" }}</b-td>
                      <b-td>{{ item.ditawarkan_tes || "-" }}</b-td>
                      <b-td>{{ item.dilakukan_tes || "-" }}</b-td>
                      <b-td>{{ item.hasil_tes_hiv || "-" }}</b-td>
                      <b-td>{{ item.mendapatkan_art || "-" }}</b-td>
                      <b-td>{{ item.persalinan_pervaginaan || "-" }}</b-td>
                      <b-td>{{ item.persalinan_pravdoinan || "-" }}</b-td>

                      <b-td>{{ item.diberikan_kelambu || "-" }}</b-td>
                      <b-td>{{ item.rdt_darah || "-" }}</b-td>
                      <b-td>{{ item.mikroskopis_darah || "-" }}</b-td>
                      <b-td>{{ item.rdt_ibu || "-" }}</b-td>
                      <b-td>{{ item.mikroskopis_ibu || "-" }}</b-td>

                      <b-td>{{ item.tes_darah_malaria || "-" }}</b-td>
                      <b-td>{{ item.obat_kina || "-" }}</b-td>
                      <!-- TB KEHAMILAN -->
                      <b-td>{{ item.diperiksa_dahak || "-" }}</b-td>
                      <b-td>{{ item.tbc || "-" }}</b-td>
                      <b-td>{{ item.obat_tbc || "-" }}</b-td>
                      <!-- KECACINGAN -->
                      <b-td>{{ item.ankylostoma || "-" }}</b-td>
                      <b-td>{{ item.tes_ibu_hamil || "-" }}</b-td>
                      <!-- IMS -->
                      <b-td>{{ item.diperiksa_ims || "-" }}</b-td>
                      <b-td>{{ item.tes_ims || "-" }}</b-td>
                      <b-td>{{ item.diterapi || "-" }}</b-td>
                      <!-- HEPATITIS -->
                      <b-td>{{ item.diperiksa_hepatitis || "-" }}</b-td>
                      <b-td>{{ item.hasil_tes_hepatitis || "-" }}</b-td>
                      <!-- RISIKO TERDETEKSI -->
                      <template v-if="item.resiko_pertama">
                        <b-td class="text-center" v-for="(a, b) in optionResiko" :key="b + 'resiko'">
                          <span v-if="a.text == item.resiko_pertama.toLowerCase()">{{ "[V]" || "[X]" }}</span>
                          <span v-else>-</span>
                        </b-td>
                      </template>
                      <template v-else>
                        <b-td v-for="(a, b) in optionResiko" :key="b + 'resiko'">
                          -
                        </b-td>
                      </template>
                      <!-- KOMPLIKASI -->
                      <template v-if="item.komplikasi">
                        <b-td class="text-center" v-for="(a, b) in optionKomplikasi" :key="b + 'komplikasi'">
                          <span v-if="a.text == item.komplikasi.toLowerCase()">{{ "[V]" || "[X]" }}</span>
                          <span v-else>-</span>
                        </b-td>
                      </template>
                      <template v-else>
                        <b-td v-for="(a, b) in optionKomplikasi" :key="b + 'komplikasi'">
                          -
                        </b-td>
                      </template>
                      <!-- RUJUKAN -->
                      <template v-if="item.dirujuk_ke">
                        <b-td class="text-center" v-for="(a, b) in optionRujukan" :key="b + 'rujukan'">
                          <span v-if="a.text == item.dirujuk_ke.toLowerCase()">{{ "[V]" || "[X]" }}</span>
                          <span v-else>-</span>
                        </b-td>
                      </template>
                      <template v-else>
                        <b-td v-for="(a, b) in optionRujukan" :key="b + 'rujukan'">
                          -
                        </b-td>
                      </template>

                      <b-td class="text-center">{{ item.tiba.toLowerCase() == 'hidup' ? "H" : "M" }}</b-td>
                      <b-td class="text-center">{{ item.pulang.toLowerCase() == 'hidup' ? "H" : "M" }}</b-td>
                      <b-td>{{ item.keterangan || "-" }}</b-td>
                    </b-tr>
                  </b-tbody>
                  
                  <b-tbody v-else>
                    <b-tr>
                      <b-td colspan="93" class="text-center text-muted">
                        <i>Tidak Ada Data</i>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                  
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "kunjungan_pasien",
  components: {
    Multiselect,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      options_kecamatan: [],
      options_kelurahan: [],
      options_diagnosa: [],
      data_search: {
        tanggal_mulai: null,
        tanggal_selesai: null,
        bulan: null,
        tahun: null,
        bulk_ms_poli_id: [],
        bulk_puskesmas_id: [],
        halaman: 0,
        jumlah: 1000,
      },
      items: [],
      sub_total_harga: 0,

      checked: false,
      checked_poli: "",
      select_puskesmas: [],
      select_poli: [],
      options_puskesmas: [],
      options_poli: [],

      optionBulan: [
        { text: "-- pilih bulan --", value: null },
        { text: "Januari", value: "Januari" },
        { text: "Februari", value: "Februari" },
        { text: "Maret", value: "Maret" },
        { text: "April", value: "April" },
        { text: "Mei", value: "Mei" },
        { text: "Juni", value: "Juni" },
        { text: "Juli", value: "Juli" },
        { text: "Agustus", value: "Agustus" },
        { text: "September", value: "September" },
        { text: "Oktober", value: "Oktober" },
        { text: "November", value: "November" },
        { text: "Desember", value: "Desember" },
      ],
      optionTahun: [
        { text: "-- pilih tuhan --", value: null },
        { text: "2021", value: "2021" },
        { text: "2022", value: "2022" },
        { text: "2023", value: "2023" },
      ],
      optionResiko: [
        { text: "pasien", value: "pasien" },
        { text: "keluarga", value: "keluarga" },
        { text: "masyarakat", value: "masyarakat" },
        { text: "dukun", value: "dukun" },
        { text: "kader", value: "kader" },
        { text: "bidan", value: "bidan" },
        { text: "perawat", value: "perawat" },
        { text: "dokter", value: "dokter" },
      ],
      optionKomplikasi: [
        { text: "dsog", value: "dsog" },
        { text: "hdk", value: "hdk" },
        { text: "abortus", value: "abortus" },
        { text: "pendarahan", value: "pendarahan" },
        { text: "infeksi", value: "infeksi" },
        { text: "kpd", value: "kpd" },
        { text: "lainnya", value: "lainnya" },
        { text: "dokter", value: "dokter" },
      ],
      optionRujukan: [
        { text: "puskesmas", value: "puskesmas" },
        { text: "klinik", value: "klinik" },
        { text: "rb", value: "rb" },
        { text: "rsia", value: "rsia" },
        { text: "rs", value: "rs" },
        { text: "lainnya", value: "lainnya" },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map((el) => el.key);
    },
  },
  activated() {
    this.data_search.tanggal_mulai = this.$moment(new Date()).format("YYYY-MM-DD");
    this.data_search.tanggal_selesai = this.$moment(new Date()).format("YYYY-MM-DD");
    this.getData();
    this.getDataKunjungan();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {
      let vm = this;
      let _puskesmas = { nama_puskesmas: vm.$store.state.puskesmas.nama_puskesmas }
      let puskesmas = await vm.$axios.post("/puskesmas/list", _puskesmas);
      if (puskesmas.data.data.length) {
        vm.options_puskesmas = puskesmas.data.data;
        for (let i = 0; i < vm.options_puskesmas.length; i++) {
          let x = vm.options_puskesmas[i];
          x.checked = false;
        }
      }
      let _poli = { nama_poli: "kia" }
      let poli = await vm.$axios.post("/ms_poli/list", _poli);
      if (poli.data.data.length) {
        vm.options_poli = poli.data.data;
        for (let i = 0; i < vm.options_poli.length; i++) {
          let x = vm.options_poli[i];
          x.checked = false;
        }
      }
    },
    getDataKunjungan() {
      let vm = this;
      if (vm.select_puskesmas.length) {
        vm.data_search.bulk_puskesmas_id = vm.select_puskesmas;
      }
      if (vm.select_poli.length) {
        vm.data_search.bulk_ms_poli_id = vm.select_poli;
      }
      vm.$axios
        .post("/kia/laporan_kunjungan_ibu_hamil", vm.data_search)
        .then((res) => {
          // console.log(res, "ini respon");
          if (res.data.status == 200) {
            let x = res.data.data
            vm.items = x
            console.log(vm.items);
          } else {
            vm.items = [];
            vm.$store.commit("set_alert", {
              msg: "TIDAK ADA DATA",
              variant: "danger",
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.$store.commit("set_alert", {
            msg: err.message,
            variant: "danger",
            showing: true,
          });
        });
    },
    // totalHarga(key, val) {
    //   let subTotal = 0
    //   for (let i = 0; i < val.length; i++) {
    //     const _el = val[i];
    //     subTotal += _el.jumlah * _el.total_harga
    //     // console.log(subTotal);
    //   }
    //   this.sub_total_harga[key] = subTotal
    // },
    toggleSelect: function () {
      let select = this.checked;
      this.options_puskesmas.forEach(function (data) {
        data.checked = select;
      });
      this.select_puskesmas = [];
      for (let i = 0; i < this.options_puskesmas.length; i++) {
        let x = this.options_puskesmas[i];
        if (x.checked == true) {
          this.select_puskesmas.push(x.id);
        }
      }
      this.$forceUpdate();
    },
    toggleSelectPoli: function () {
      let select = this.checked_poli;
      // console.log(this.checked_poli);
      this.options_poli.forEach(function (data) {
        data.checked = select;
      });
      this.select_poli = [];
      for (let i = 0; i < this.options_poli.length; i++) {
        let x = this.options_poli[i];
        if (x.checked == true) {
          this.select_poli.push(x.ms_poli_id);
        }
      }
      this.$forceUpdate();
    },
    addSelect() {
      let vm = this;
      vm.select_puskesmas = [];
      for (let i = 0; i < vm.options_puskesmas.length; i++) {
        let x = vm.options_puskesmas[i];
        if (x.checked == true) {
          vm.select_puskesmas.push(x.id);
        }
      }
    },
    addSelectPoli() {
      let vm = this;
      vm.select_poli = [];
      for (let i = 0; i < vm.options_poli.length; i++) {
        let x = vm.options_poli[i];
        if (x.checked == true) {
          vm.select_poli.push(x.ms_poli_id);
        }
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
      vm.getDatas();
    },
    resetDataKunjungan() {
      this.data_search = {
        tanggal_mulai: this.$moment().format("YYYY-MM-DD"),
        tanggal_selesai: this.$moment().format("YYYY-MM-DD"),
        bulk_ms_poli_id: [],
        bulk_puskesmas_id: [],
        halaman: 0,
        jumlah: 1000,
      }
      this.select_puskesmas = []
      this.select_poli = []
      this.getData()
      this.getDataKunjungan()
    },
  },
};
</script>

<style>
#kunjungan-ibu-hamil .custom-table td {
  padding: 0 !important;
  font-size: 14px;
  font-weight: bold;
  border: hidden !important;
}

#kunjungan-ibu-hamil .custom-control-label {
  padding-top: 2.5px;
}
.top-text-variant {
  font-size: 10px;
}
</style>
